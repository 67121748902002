<template>
  <v-row
    v-if="resevationDone || studio.tattooers.length === 0"
    id="inscription-studio"
    class="final"
    style="overflow: hidden"
  >
    <v-img height="900px" src="@/assets/background.jpg"
      ><v-row
        justify="center"
        align="center"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top:8%'
            : $vuetify.breakpoint.lgAndDown
            ? 'margin-top: 5%'
            : ''
        "
      >
        <v-col cols="8" sm="5" md="2">
          <img width="100%" src="@/assets/logo.png" />
        </v-col>
      </v-row>
      <v-row justify="center"
        ><v-col
          cols="12"
          :md="studio.tattooers.length === 0 ? 6 : 4"
          class="pa-0"
          src="@/assets/background.jpg"
        >
          <h1
            v-if="
              studio.tattooers.length === 0 && $store.getters['auth/isLoggedIn']
            "
            style="
              text-transform: uppercase;
              color: white !important;
              font-size: 22px;
            "
            v-html="$t('inscriptions.no_tattooer_studio')"
          ></h1>
          <h1
            v-else-if="studio.tattooers.length === 0"
            style="
              text-transform: uppercase;
              color: white !important;
              font-size: 22px;
            "
          >
            <p class="py-0">
              {{ $t("inscriptions.no_tattooer") }}
            </p>
            <p class="py-0">
              {{ $t("inscriptions.no_tattooer_contact") }}
            </p>
            <p class="py-0" v-if="studio.user.email">
              <a :href="`mailto:${studio.user.email}`" style="color: white">{{
                studio.user.email
              }}</a>
            </p>
            <p class="py-0" v-if="studio.user.phone">
              <a :href="`tel:${studio.user.phone}`" style="color: white">{{
                studio.user.phone
              }}</a>
            </p>
          </h1>
          <h1
            v-else
            style="
              text-transform: uppercase;
              color: white !important;
              font-size: 22px;
            "
          >
            {{ $tc("inscriptions.customer_studio") }}<br />
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center" v-if="$store.getters['auth/isLoggedIn']">
        <v-btn
          v-if="studio.tattooers.length === 0"
          style="height: 30px"
          outlined
          @click="$router.push({ name: 'tattooerList' })"
          >{{ $tc("tattooers.go", 2) }}</v-btn
        >
        <v-btn
          v-else-if="studio.tattooers.length > 0"
          style="height: 30px"
          outlined
          @click="$router.push({ name: 'appointmentList' })"
          >{{ $tc("appointments.go", 2) }}</v-btn
        >
      </v-row>
      <v-row justify="center" v-else>
        <v-btn style="height: 30px" outlined :href="ap_id">{{
          $t("appointments.go_customer")
        }}</v-btn>
      </v-row>
    </v-img>
  </v-row>
  <studio-card v-else-if="processed" id="inscription-studio">
    <template slot-scope="{}">
      <payment v-if="pi" :pi="pi" @payed="resevationDone = true" />
      <v-stepper v-model="e1" alt-labels v-else>
        <v-stepper-header>
          <template v-if="!t_id">
            <v-stepper-step
              step="1"
              :complete="e1 > 1"
              v-bind:class="[{ pointer: e1 > 1 }]"
              @click="e1 > 1 ? (e1 = 1) : null"
              ><div
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                "
              >
                {{ $t("appointments.select_tattooer") }}
              </div></v-stepper-step
            >
            <v-divider style="margin-top: 4em"></v-divider>
          </template>
          <v-stepper-step
            :step="t_id ? 1 : 2"
            :complete="t_id ? e1 > 1 : e1 > 2"
            v-bind:class="[{ pointer: t_id ? e1 > 1 : e1 > 2 }]"
            @click="t_id ? e1 > 1 : e1 > 2 ? (e1 = t_id ? 1 : 2) : null"
            ><div
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              <span v-if="artist == 'piercer'">
                {{ $t("appointments.info_piercing") }}
              </span>
              <span v-else-if="artist == 'laser'">
                {{ $t("appointments.info_laser") }}
              </span>
              <span v-else-if="artist == 'microblading'">
                {{ $t("appointments.info_microblading") }}
              </span>
              <span v-else>{{ $t("appointments.info_tattoo") }}</span>
            </div>
          </v-stepper-step>

          <v-divider style="margin-top: 4em"></v-divider>
          <v-stepper-step
            :step="t_id ? 2 : 3"
            :complete="t_id ? e1 > 2 : e1 > 3"
            v-bind:class="[{ pointer: t_id ? e1 > 2 : e1 > 3 }]"
            @click="t_id ? e1 > 2 : e1 > 3 ? (e1 = t_id ? 2 : 3) : null"
            ><div
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              {{
                $store.getters["inscriptions/getTattooerLaborExange"](
                  customer.studio
                ) === "day"
                  ? $t("appointments.day_hour")
                  : $t("appointments.day")
              }}
            </div>
          </v-stepper-step>
          <v-divider style="margin-top: 4em"></v-divider>
          <v-stepper-step
            :step="t_id ? 3 : 4"
            :complete="t_id ? e1 > 3 : e1 > 4"
            v-bind:class="[{ pointer: t_id ? e1 > 3 : e1 > 4 }]"
            @click="t_id ? e1 > 3 : e1 > 4 ? (e1 = t_id ? 3 : 4) : null"
            ><div
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              {{ $t("appointments.info_cliente") }}
            </div>
          </v-stepper-step>

          <v-row
            v-if="$vuetify.breakpoint.smAndDown"
            style="display: block; width: 100%; margin-top: -30px"
            justify="center"
            ><v-col>
              <div
                v-if="e1 === 4"
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                "
              >
                {{ $t("appointments.info_cliente") }}
              </div>
              <div
                v-else-if="e1 === 1"
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                "
              >
                {{ $t("appointments.select_tattooer") }}
              </div>
              <div
                v-else-if="e1 === 2"
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                "
              >
                <span v-if="artist == 'piercer'">
                  {{ $t("appointments.info_piercing") }}
                </span>
                <span v-else-if="artist == 'laser'">
                  {{ $t("appointments.info_laser") }}
                </span>
                <span v-else-if="artist == 'microblading'">
                  {{ $t("appointments.info_microblading") }}
                </span>
                <span v-else>{{ $t("appointments.info_tattoo") }}</span>
              </div>
              <div
                v-else
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                "
              >
                {{
                  $store.getters["inscriptions/getTattooerLaborExange"](
                    customer.studio
                  ) === "day"
                    ? $t("appointments.day_hour")
                    : $t("appointments.day")
                }}
              </div>
            </v-col>
          </v-row>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1" v-if="!t_id" class="px-1 px-md-6">
            <tattooer-select
              @validated="artistType"
              ref="infoTattooer"
              @back="e1--"
            />
          </v-stepper-content>
          <v-stepper-content :step="t_id ? 1 : 2">
            <div v-if="artist == 'tattooer'">
              <tattoo-info @validated="e1++" @back="e1--"></tattoo-info>
            </div>

            <div v-else-if="artist == 'laser'">
              <laser-info @validated="e1++" @back="e1--" />
            </div>
            <div v-else-if="artist == 'piercing'">
              <PiercingInfo @validated="e1++" @back="e1--"></PiercingInfo>
            </div>
            <div v-else>
              <microblading-info @validated="e1++" @back="e1--" />
            </div>
          </v-stepper-content>
          <v-stepper-content :step="t_id ? 2 : 3">
            <PrepareTimeSelect @validated="e1++" @back="e1--" />
          </v-stepper-content>
          <v-stepper-content :step="t_id ? 3 : 4">
            <customer-info @back="e1--" @validated="save"></customer-info>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </studio-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ClientIncription",
  components: {
    StudioCard: () => import("@/components/inscription/studio/Card"),
    TattooInfo: () => import("@/components/inscription/tattooer/TattooInfo"),
    PiercingInfo: () =>
      import("@/components/inscription/tattooer/PiercingInfo"),
    MicrobladingInfo: () =>
      import("@/components/inscription/tattooer/MicrobladingInfo"),
    LaserInfo: () => import("@/components/inscription/tattooer/LaserInfo"),
    TattooerSelect: () =>
      import("@/components/inscription/customer/TattooerSelect"),
    DateTimeSelect: () =>
      import("@/components/inscription/customer/DateTimeSelect"),
    HelpDateTimeSelect: () =>
      import("@/components/inscription/customer/HelpDateTimeSelect"),
    LaborExange: () => import("@/components/inscription/customer/LaborExange"),
    PrepareTimeSelect: () =>
      import("@/components/inscription/studio/PrepareTimeSelect"),
    CustomerInfo: () =>
      import("@/components/inscription/customer/CustomerInfo"),
    payment: () => import("@/components/inscription/customer/Payment"),
  },
  mounted() {
    if (!this.$route.params.pre)
      this.$store.commit("inscriptions/RESET_CUSTOMER");
    this.t_id = this.$route.params.tattooer_id;
    if (this.$route.params.artist) {
      console.log(this.$route.params.artist);
      this.artist = this.$route.params.artist;
      if (typeof this.artist === "object") this.artist = this.artist.name;
      this.customer.type = this.artist;
      setTimeout(() => {
        if (this.$refs.dateTimeSelect) {
          this.$refs.dateTimeSelect.setDay();
        }
      }, 1000);
    }
    if (this.$route.params.customer)
      this.customer = {
        ...this.$route.params.customer,
        files: [],
        inner_part: null,
        inner_part_other: null,
        date: null,
      };

    this.customer.from = "studio";

    if (this.t_id) this.customer.from = "tattooer";

    this.customer.timezone = -new Date().getTimezoneOffset();

    this.fetchStudio();
  },
  data() {
    return {
      resevationDone: false,
      pi: null,
      e1: 1,
      artist: null,
      t_id: null,
      ap_id: null,
      processed: false,
    };
  },
  computed: {
    ...mapState("inscriptions", ["studioState", "customerState"]),
    studio: {
      get() {
        return this.studioState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_STUDIO", value);
      },
    },
    customer: {
      get() {
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },
  },
  methods: {
    ...mapActions("studios", ["getStudioByToken"]),
    fetchStudio() {
      this.getStudioByToken(this.$route.params.token).then((response) => {
        this.studio = response;
        this.$set(this.customer, "studio", response);
        if (this.t_id && this.t_id != -1) {
          //console.log("set Tattooer");
          this.$set(
            this.customer,
            "tattooer",
            this.studio.tattooers.find((x) => x.id === this.t_id)
          );
        } else if (this.t_id === -1)
          this.$set(this.customer, "tattooer", { id: -1, tattoo_type: [] });

        this.$store.commit("inscriptions/SET_CUSTOMER", this.customer);
        this.customer.reservation_type = this.$store.getters[
          "inscriptions/getTattooerLaborExange"
        ](
          response,
          this.t_id === -1 ? "labor_exange_tattooer" : "labor_exange"
        );

        this.processed = true;
      });
    },
    ...mapActions("inscriptions", ["customerInscription"]),
    save() {
      //console.log(this.$store.state.inscriptions.customerState);
      if (this.$store.state.inscriptions.customerState.inner_part === "Otro")
        this.$store.state.inscriptions.customerState.inner_part =
          this.$store.state.inscriptions.customerState.inner_part_other;
      this.customerInscription().then((data) => {
        this.url(data.id);
        if (data.marketplace) this.pi = data.marketplace;
        else this.resevationDone = true;
      });
    },
    url(id) {
      console.log(this.customer);
      this.ap_id = `${window.location.origin}/?#/cliente/${id}/appointment/${id}`;
    },
    artistType(value) {
      this.e1++;
      this.artist = value;
      this.customer.type = value;

      if (!this.t_id) $refs.infoTattooer.fetchType();

      if (this.$refs.dateTimeSelect) {
        this.$refs.dateTimeSelect.setDay();
      }
    },
  },
};
</script>

<style lang="sass">
#inscription-studio
  &.final
    height: 100vh

    .v-responsive__sizer
      display: none !important
</style>